class Elementor_Nav_Menu {
    static instance;
           settings = {};
           $toggle;
           $dropdownMenu

    static getInstance() {
        if (!Elementor_Nav_Menu.instance) {
            Elementor_Nav_Menu.instance = new Elementor_Nav_Menu();
        }
        return Elementor_Nav_Menu.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }


    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-nav-menu.default', ($scope) => {
            this.setupToggleButton($scope);  
            this.setupMenu($scope);
          //  this.megamenuFullWidth($scope);
        });
    }
    setupMenu($scope) {  
        
        let isvertical = $scope.find(' > .elementor-widget-container > .opalelementor-nav-menu--layout-vertical').length; 

        if( $scope.find('.opalelementor-nav-menu .sub-menu').length > 0 ){ 
            let subMenusMinWidth = $scope.find('nav.opalelementor-nav-menu--main').data('submenusminwidth');
            let subMenusMaxWidth = $scope.find('nav.opalelementor-nav-menu--main').data('submenusmaxwidth');
            subMenusMaxWidth = 1000;  
           
            var  $_menus; 

            $_menus = $scope.find('.opalelementor-nav-menu').smartmenus({
                subIndicatorsText: '',
                subIndicatorsPos : 'append',
                subMenusMinWidth : subMenusMinWidth+'px',
                subMenusMaxWidth : subMenusMaxWidth+'px',
                // subMenusSubOffsetY: 100,
                showTimeout      : 0.,
                collapsibleBehavior: 'accordion'
            }); 

           $_menus.on( {
                'show.smapi': function( e, menu ) {  
                    $( menu ).removeClass( 'hide-animation' ).addClass( 'show-animation' );

                    let $parent       = $scope.closest('.elementor-container');
                
                    if( $(menu).hasClass("mega-menu") ) {
                        if ( $(menu).hasClass("mega-fullwidth") ) {
                            if( isvertical ){

                              

                                let width =  $(window).width() - ($(this).offset().left + $(this).width());

                                if( width > 1000 ){
                                    width = 1000;
                                }    

                                $(menu).css({
                                    width     : width,
                                    'max-width':width
                                });   
                            } else {
                                let width = $parent.width(); 
                                $(menu).css({
                                    width     : width,
                                    'max-width':width,
                                    marginLeft: $parent.offset().left - $(menu).offset().left
                                });   
                            }
                        }else if ($(menu).hasClass("mega-stretchwidth") && isvertical ) {  
                            let width =  $(window).width() - ($(this).offset().left + $(this).width());
                            $(menu).css({
                                width     : width,
                                'max-width':width
                            });   
                        } else if( $(menu).hasClass("custom-subwidth") ){
                            let width =   $(menu).data( 'subwidth' );
                            if( isvertical ){
                                $(menu).css({
                                    width     : width,
                                    'max-width':width
                                }); 
                            } else {
                                $(menu).css({
                                    width     : width,
                                    'max-width':width,
                                    marginLeft: $parent.offset().left  
                                }); 
                            }
                           
                        }
                   }
                },
                'hide.smapi': function( e, menu ) {
                    $( menu ).removeClass( 'show-animation' ).addClass( 'hide-animation' );
                }
            } ).on( 'animationend webkitAnimationEnd oanimationend MSAnimationEnd', 'ul', function( e ) {
                $( this ).removeClass( 'show-animation hide-animation' );
                e.stopPropagation();
            } )

       }    
    }

    megamenuFullWidth($scope) { /* 
        let $megamenuitem = $scope.find('.has-mega-menu'),
            $parent       = $scope.closest('.elementor-container');
        if ($scope.find(' > .elementor-widget-container > .opalelementor-nav-menu--layout-horizontal').length) {
 
            let width = $parent.width();
            if ( $megamenuitem.find('.mega-fullwidth').length) {
                $megamenuitem.find('.mega-fullwidth').css({
                    width     : width,
                    marginLeft: $parent.offset().left -  $megamenuitem.offset().left
                });
            }   
        } */
    }

    setupToggleButton($scope) {
        if (this.isMobileMenu($scope)) {
            $scope.$toggle = $scope.find('.opalelementor-menu-toggle');
            var style =  $scope.$toggle.data('style') ;
            var effect =  $scope.$toggle.data('effect') 
            
            if( style == 'poup' ){  
                $scope.$toggle.magnificPopup({
                    type        : 'inline',
                    mainClass: 'mfp-with-fade',
                    removalDelay: 500,
                    overflowY   : 'hidden',
                    callbacks   : {
                        beforeOpen: function () {  
                            this.st.mainClass = effect;
                        },
                        close: function () {
                             $scope.$toggle.removeClass('opalelementor-active');
                        }
                    },
                    midClick    : true
                });

            } else {
                $scope.$toggle.on('click', () => { 
                    this.$dropdownMenu = $scope.find('.opalelementor-nav-menu--dropdown.opalelementor-nav-menu__container');
                    $scope.$toggle.toggleClass('opalelementor-active');
                    this.toggleMenu($scope, $scope.$toggle.hasClass('opalelementor-active'));
                    return false; 
                });
            }   
        }
    }

    isMobileMenu($scope) {
        return $scope.find('.opalelementor-menu-toggle').length > 0;
    }

    toggleMenu($scope, show) {
        let $dropdownMenu = this.$dropdownMenu;
        let settings      = $scope.data('settings');
        if (show) {
            $dropdownMenu.hide().slideDown(250, function () {
                $dropdownMenu.css('display', '');
            });

            if (settings.full_width) {
                $dropdownMenu.css(this.menuFullWidth($scope, $dropdownMenu));
            }

        } else {
            $dropdownMenu.show().slideUp(250, function () {
                $dropdownMenu.attr('style', '');
            });
        }
    }

    menuFullWidth($scope, $dropdownMenu) {
        let $container     = $(window),
            containerWidth = $container.outerWidth(),
            elementOffset  = $dropdownMenu.offset().left;

        return {
            'top'  : $scope.$toggle.outerHeight(),
            'left' : -elementOffset + 'px',
            'width': containerWidth,
        }
    }
}

Elementor_Nav_Menu.getInstance();